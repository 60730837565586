<template>
  <div>
    <v-row class="mx-2">
      <v-col cols="12">
        <v-card flat style="border-radius: 12px" elevation="3">
          <v-card-title>
            <v-row>
              <v-col class="d-flex align-center justify-start" cols="8"
                ><h5 class="d-flex align-left">{{ $t("verifires") }}</h5>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('search-domain')"
                  single-line
                  hide-details
                  filled
                  dense
                  background-color="#E8E8E8"
                  rounded
                  class="table-search-button"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
              dense
            :headers="headers"
			:no-data-text="$t('no-data-available')"
      :loading-text="$t('loading')"
            :loading="loading"
            :search="search"
            :items="hospitals"
            :items-per-page="-1"
            group-by="rvDomain"
            style="cursor: pointer"
              ref="table"
            :height="testHeight"
			:single-expand="true"
            :expanded.sync="expanded"
              :footer-props="{
          itemsPerPageText: this.$t('row'),
          itemsPerPageAllText: this.$t('all'),
        }"
          >
            <template v-slot:expanded-item="{ headers, item, toggle }">
              <td @click="toggle" :colspan="headers.length">
                <v-row class="pa-4">
                  <v-col cols="12">
                    <v-card flat>
                      <v-card-title>{{ item.rvDomain }}</v-card-title>
                      <v-row
                        class="mx-4"
                        v-for="(item, i) in hospitalAdmins"
                        :key="i"
                        style="backgroudncolor: red"
                      >
                        <v-checkbox
                          :label="item.firstName"
                          @change="assignAdminToHospital($event, item.id)"
                        ></v-checkbox>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </td>
            </template>
            <template v-slot:item="{ item }">
              <tr>
                <td style="text-align: start">
                  {{ $i18n.locale === 'en' ? item.shortDescription : item.shortDescriptionEsp }}
                </td >
                <td style="text-align: start">{{ item.lowValue }}</td>
                <td style="text-align: end">
                  <v-icon @click="hospitaldetails(item)" color="primary">mdi-pencil</v-icon>
<!--                  <v-icon color="red">mdi-delete</v-icon>-->
                </td>
              </tr>
            </template>
            <template
              v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
              <td  @click="toggle" :colspan="headers.length" style="background-color:white;">
                <v-row class="align-center">
                  <v-col class="text-left" cols="3">
                <span
                    style="font-weight: bold; margin-left: 2px; margin-right: 8px"
                >{{ group }}</span
                >
                  </v-col>
              <v-col cols="1">
                <v-chip  class="mb-1 white--text" pill x-small color="primary">
                  {{ items.length }}
                </v-chip>
              </v-col>
                 <v-col>
                  <span style="float: right;">
          <v-icon v-if="isOpen" @click="addVerifiere(items)" color="primary">mdi-plus</v-icon>
					<v-btn small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen" color="primary">mdi-arrow-up-drop-circle</v-icon>
                    <v-icon v-else color="primary">mdi-arrow-down-drop-circle</v-icon>
                </v-btn>
				</span>
                 </v-col>

                </v-row>

              </td>
            </template>
            <template v-slot:[`footer.prepend`]>
              <v-btn
                v-if="roleName === 'SYSTEM_ADMIN'"
                class="table-create-button"
                label="Test"
                @click="addHospital"
              >
				<img class="pt-1" height="20px" width="20px" :src="verifireWhite" style="margin-right:3%; margin-bottom: 5%;"/>
                <span style="text-transform: none;">{{ $t("add_verifire") }}</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <AddVerifire
          v-if="dialog"
          :dialog="dialog"
          :type="dialogType"
          :selectedverifier="selectedHospital"
          @false="hospitalUpdated"
          @close="closeDialog"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import AddVerifire from '../dialogs/AddVerifire';
import { verifireWhite } from '@/assets';

export default {
	components: {
		AddVerifire,
	},
	data: () => ({
		dialog: false,
		dialogType: null,
		loading: false,
		hospitals: [],
		togglers: {},
		hospitalId: -1,
		search: '',
		headerClass: 'black_inner--text !important',
		hospitalAdmins: [],
		selectedHospital: null,
		expanded: [],
		verifireWhite: verifireWhite,
	}),
	computed: {
		...mapState({
			allverifires: (state) => state.verifiers.allverifires,

		}),
		...mapGetters({ roleName: 'authentication/getRole', delay: 'authentication/getDelay' }),
		testHeight () {
			switch (this.$vuetify.breakpoint.name) {
			case 'xs':
				return window.innerHeight - 320;
			case 'sm':
				return window.innerHeight - 300;
			case 'md':
				return window.innerHeight - 300;
			case 'lg':
				return window.innerHeight - 280;
			case 'xl':
				return window.innerHeight - 280;
			default:
				return 0;
			}
		},
		headers () {
			var headers = [];
			headers = [
				{
					text: this.$t('name'),
					value: 'shortDescription',
					class: this.headerClass,
				},
				{
					text: this.$t('lowvalue'),
					value: 'lowValue',
					class: this.headerClass,
				},
				{
					text: '',
					sortable: false,
					value: 'contactPerson',
					class: this.headerClass,
				},
			];
			return headers;
		},
	},
	async mounted () {
		await this.getHospitals();
		// this.closeAll();
	},
	methods: {
		closeAll () {
			Object.keys(this.$refs).forEach(k => {
				if (this.$refs[k] && this.$refs[k].$attrs['data-open']) {
					this.$refs[k].$el.click();
				}
			});
		},
		toggleHeaders () {
			const table = this.$refs.table;
			if (table) {
				const keys = Object.keys(table.$vnode.componentInstance.openCache);
				keys.forEach((x) => {
					table.$vnode.componentInstance.openCache[x] = false;
				});
			}
		},
		async getHospitals () {
			this.toggleHeaders();
			this.loading = true;
			await this.$store.dispatch('verifiers/getVerifiers').then(() => {
				this.hospitals = this.allverifires;
				this.loading = false;
			});
			this.toggleHeaders();
		},
		addHospital () {
			this.dialog = true;
			this.dialogType = 'add';
		},
		hospitaldetails (item) {
			this.dialog = true;
			this.dialogType = 'edit';
			this.selectedHospital = item;
		},
		addVerifiere (item) {
			this.dialog = true;
			this.dialogType = 'addVerifiere';
			this.selectedHospital = item;
		},
		closeDialog () {
			this.dialog = false;
			this.dialogType = null;
			this.selectedHospital = null;
			this.getHospitals();
		},
		hospitalUpdated (res) {
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'addedHospital',
					type: 'success',
					color: 'main_green',
					// message: res.msg,
					message: this.$t('success'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'addedHospital',
					type: 'error',
					color: 'main_red',
					// message: res.msg,
					message: this.$t('failed'),
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
			this.dialog = false;
			this.dialogType = null;
			this.selectedHospital = null;
			this.getHospitals();
		},
	},
};
</script>
