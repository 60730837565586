<template>
  <v-dialog
    v-model="dialog"
    width="800"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent
    style="background-color: blue; border-radius: 30px !important"
  >
    <v-form v-model="valid">
      <v-card flat style="overflow: hidden">
        <v-card-title class="secondary py-1" style="height: 40px">
          <span class="dialog-title"
            >{{ type === "add" ? $t("create") : $t("edit") }}
            {{ $t("verifires") }}</span
          >
          <v-spacer />
          <v-icon small color="primary" @click="$emit('close')"
            >mdi-window-close</v-icon
          >
        </v-card-title>

        <v-row class="px-6 pt-3">
          <v-col cols="12">
            <v-row dense cols="12" class="px-1 mb-2">
				<v-col >
          <v-text-field
              v-model="verifire"
              :label="this.$t('domainname')"
              dense
              :disabled="type === 'add' ? false : true"
              background-color="#E8E8E8"
              rounded
              outlined
              style="border-radius: 12px !important; height: 40px"
          ></v-text-field>
				</v-col>
              <v-col>
                <v-text-field
                    v-model="lowvalue"
                    :label="this.$t('lowvalue')"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-2">
				<v-col cols="12" lg="6" md="6" sm="12">
					<v-text-field
						v-model="shortdescription"
						:label="this.$t('shortdescription')"
						dense
						background-color="#E8E8E8"
						rounded
						outlined
						style="border-radius: 12px !important; height: 40px"
					></v-text-field>
				</v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
				<v-text-field
					v-model="shortdescriptionesp"
          :label="this.$t('shortdescriptionesp')"
					dense
					background-color="#E8E8E8"
					rounded
					outlined
					style="border-radius: 12px !important; height: 40px"
				></v-text-field>
				</v-col>
            </v-row>
            <v-row dense cols="12" class="px-1 mb-2">
              <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="longdescription"
                    validate-on-blur
                    :label="this.$t('longdescription')"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                  ></v-text-field>
              </v-col>
              <v-col cols="12" lg="6" md="6" sm="12">
                  <v-text-field
                    v-model="longdescriptionesp"
                    :label="this.$t('longdescriptionesp')"
                    dense
                    background-color="#E8E8E8"
                    rounded
                    outlined
                    style="border-radius: 12px !important; height: 40px"
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions class="px-6">
          <Alert
            class="mt-4"
            v-if="showErrors"
            :title="title"
            :type="alertType"
          ></Alert>
          <v-spacer />
          <div class="my-3">
            <v-btn class="table-cancel-button" @click="$emit('close')">
              <span color="black--text">{{ $t("cancel") }}</span>
            </v-btn>
			<v-btn
				class="table-create-button mx-2"
				:loading="loading"
				:disabled="loading || !valid"
				@click="loader = 'loading'"
			>
				<span>{{ $t("save") }}</span>
			</v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import Alert from '@/views/Client/components/AlertDialog.vue';

export default {
	components: {
		Alert,
	},
	props: ['dialog', 'type', 'selectedverifier'],
	data () {
		return {
			loader: null,
			loading: false,
			showErrors: false,
			title: 'Something went wrong',
			alertType: false,
			domainName: null,
			valid: false,
			verifire: null,
			lowvalue: null,
			shortdescription: null,
			shortdescriptionesp: null,
			longdescription: null,
			longdescriptionesp: null,
			medicalInstitutions: [],
			validationErrors: [],
		};
	},
	computed: {
		...mapState({
			medicalInstitutionsFromRepo: (state) =>
				state.medicalInstitutions.medicalInstitutions,
		}),
	},
	watch: {
		verifire (val) {
			val = val.replace(' ', '_');
			this.domainName = val.toUpperCase();
		},
		async loader () {
			if (this.loader !== null) {
				const l = this.loader;
				this[l] = !this[l];

				await this.createVerifire()
					.then(() => {
						this[l] = false;
					})
					.catch(() => {
						this[l] = false;
					});

				this.loader = null;
			}
		},
	},
	async mounted () {
		if (this.type === 'edit') {
			this.verifireId = this.selectedverifier.id;
			this.verifire = this.selectedverifier.rvDomain;
			this.lowvalue = this.selectedverifier.lowValue;
			this.shortdescription = this.selectedverifier.shortDescription;
			this.shortdescriptionesp = this.selectedverifier.shortDescriptionEsp;
			this.longdescription = this.selectedverifier.longDescription;
			this.longdescriptionesp = this.selectedverifier.longDescriptionEsp;
		} else if (this.type === 'addVerifiere') {
			this.verifireId = this.selectedverifier[this.selectedverifier.length - 1].id;
			this.verifire = this.selectedverifier[this.selectedverifier.length - 1].rvDomain;
			this.lowvalue = (this.selectedverifier[this.selectedverifier.length - 1].lowValue + 1);
		}
		// await this.getMedicalInstitutions();
	},
	methods: {
		async createVerifire () {
			this.validationErrors = [];
			this.showErrors = false;
			const body = {
				id: this.verifireId,
				rvDomain: this.domainName,
				lowValue: this.lowvalue,
				shortDescription: this.shortdescription,
				longDescription: this.longdescription,
				shortDescriptionEsp: this.shortdescriptionesp,
				longDescriptionEsp: this.longdescriptionesp,
			};
			switch (this.type) {
			case 'add':
				this.createhospital(body);
				break;
			case 'edit':
				this.updatehospital(body);
				break;
			case 'addVerifiere':
				this.createhospital(body);
				break;
			default:
				break;
			}
		},
		async createhospital (body) {
			await this.$store
				.dispatch('verifiers/createVerifire', body)
				.then(res => {
					this.$emit('false', res);
				});
		},
		async updatehospital (body) {
			// body.id = this.hospital.id;
			await this.$store.dispatch('verifiers/editVerifire', body).then(res => {
				this.$emit('false', res);
			});
		},
	},
};
</script>
